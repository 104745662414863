import axios from 'axios';

const Api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

export function setupInterceptors() {
    Api.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response) {
                const errorMessage = error.response.data;
                const errorStatus = error.response.status;

                if (errorMessage === "Expired token" || errorMessage === "Signature verification failed" || errorMessage === "Syntax error, malformed JSON" || errorStatus === 500) {
                    // Limpa todos os localStorage
                    localStorage.clear();

                    // Redireciona o usuário para a página de login usando o método tradicional
                    window.location.href = "/login";
                }
            }
            return Promise.reject(error);
        }
    );
}

export default Api;