import { useMemo, useState, useEffect } from "react";
import TableUsuarios from '../../Components/TableUsuarios';
import Api from '../../Auth/Api';

function ListaUsuariosGestor() {

    const token = JSON.parse(localStorage.getItem("user_token"));

    const [dataUsuarios, setDataUsuarios] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-usuarios-tutor`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataUsuarios(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token]);
    

    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: 'foto',
                Cell: () => (
                    <div className="d-flex align-items-center">
                        <div className="picture text-white bg-grey" style={{ fontSize: '22px', height: '42px', marginRight: '0px', width: '42px' }}>
                            <i className="bi bi-person" />
                        </div>
                    </div>
                )
            },
            {
                Header: () => null,
                accessor: 'nome_completo'
            },
            {
                Header: () => null,
                accessor: 'email'
            },
            {
                Header: () => null,
                accessor: 'unidade'
            }
        ],
        []
    )

    const filterSelect = [
        { value: 'Aluno', label: 'Alunos' },
        { value: 'Gestor', label: 'Gestores' },
        { value: 'Administrador', label: 'Administradores' }
    ]

    return (
        <>
            <div className="fade-in w-100 mb-5">
                <span className="text-primary fs-1">Lista de Usuários</span>
                <p className="fs-6">Consulte os usuários cadatrados, filtre, edite e exclua.</p>
                <div className="body rounded bg-white px-3 py-3">
                    <section className="w-100">
                        <TableUsuarios columns={columns} data={dataUsuarios} nivel="tutor" filterSelect={filterSelect} titulo="Lista de Usuários" />
                    </section>
                </div>
            </div>
        </>
    )
}

export default ListaUsuariosGestor;