import React, { useEffect } from 'react';
import { useTable, usePagination, useGlobalFilter } from "react-table"
import Select from 'react-select';

function Table({ columns, data, filterSelect, setSelectedFilter, nivel = 'Administrador', filterTipo, setSelectedTipo, length = true, search = true, titulo = '', unidades, selectedUnidade, setSelectedUnidade }) {
    const props = useTable({ columns, data, initialState: { pageSize: 20 } }, useGlobalFilter, usePagination);
    const {
        getTableBodyProps,
        rows,
        prepareRow,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter }
    } = props;
    useEffect(() => {
    }, [globalFilter]);

    const customStyles = {
        container: (base) => ({
            ...base,
            width: '100%',
        }),
        control: (base, state) => ({
            ...base,
            fontSize: "1rem",
            backgroundColor: "#ffffff",
            borderRadius: "0.25rem",
            boxShadow: state.isFocused ? "0 0 0 0.25rem #27485b73" : null,
            borderColor: "#dee2e6",
            "&:hover": {
                borderColor: "#dee2e6"
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: '#000',
            marginTop: '5px',
        }),
        input: (base) => ({
            ...base,
            height: "46px",
            margin: '0px',
            padding: '0px !important',
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#fff',
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? '#3a5a6c' : null,
            backgroundColor: state.isFocused ? '#27485b73' : null,
            "&:active": {
                backgroundColor: "#27485b73"
            },
        }),
    };

    return (
        <>
            <section className="w-100">
                <div className="d-flex flex-column">
                    <div className="row d-flex align-items-center justify-content-start">
                        <div className={`col-md-${nivel === 'Administrador' ? '8' : '11'} col-lg-${nivel === 'Administrador' ? '8' : '11'} col-xl-${nivel === 'Administrador' ? '6' : '9'}`}>
                            <div className="inner-addon right-addon">
                                <i className="d-flex bi bi-search" />
                                <input
                                    value={globalFilter || ""}
                                    onChange={e => setGlobalFilter(e.target.value)} type="search"
                                    placeholder="Pesquise pelo nome desejado...."
                                    className="form-control py-2"
                                />
                            </div>
                        </div>
                        {nivel === 'Administrador' &&
                            <div className="d-flex mt-2 mt-md-0 align-items-center justify-content-start col-md-4 col-xl-3 col-lg-4">
                                <Select
                                    options={filterSelect.map((value) => {
                                        return {
                                            value: value.value,
                                            label: `${value.label}`,
                                        };
                                    })}
                                    defaultValue={{ value: filterSelect[0].value, label: filterSelect[0].label }}
                                    styles={customStyles}
                                    placeholder="Filtro de nível"
                                    onChange={(selectedOption) => {
                                        setSelectedFilter(selectedOption ? selectedOption.value : null);
                                    }}
                                />
                            </div>
                        }
                        <div className="d-flex mt-2 mt-xl-0 mt-xxl-0 align-items-center justify-content-start col-md-4 col-xl-3">
                            <Select
                                options={filterTipo.map((value) => {
                                    return {
                                        value: value.value,
                                        label: `${value.label}`,
                                    };
                                })}
                                defaultValue={{ value: filterTipo[0].value, label: filterTipo[0].label }}
                                styles={customStyles}
                                placeholder="Filtro de tipo"
                                onChange={(selectedOption) => {
                                    setSelectedTipo(selectedOption ? selectedOption.value : null);
                                }}
                            />
                        </div>
                        <div className="d-flex mt-2 align-items-center justify-content-start col-md-3 col-lg-3 col-xl-2 col-xxl-2">
                            <select className="form-select py-2" value={pageSize} onChange={e => { setPageSize(Number(e.target.value)) }}>
                                <option disabled>Quantidade</option>
                                {[20, 50, 100, 500, 1000].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex mt-2 align-items-center justify-content-start col-md-5 col-12 col-xl-4 col-xxl-3 col-lg-5">
                            <Select
                                options={unidades.map((value) => {
                                    return {
                                        value: value.nome,
                                        label: `${value.nome}`,
                                    };
                                })}
                                styles={customStyles}
                                placeholder="Filtro de unidade"
                                onChange={(selectedOption) => {
                                    setSelectedUnidade(selectedOption ? selectedOption : null);
                                }}
                                isClearable
                            />
                        </div>
                    </div>
                </div>
            </section>
            <div className="table-full-width mt-3 mb-3">
                <div className="table-responsive">
                    <table className="table align-middle mt-1 table-striped table-hover">
                        <thead>
                            <tr>
                                <th colSpan="7" className='bg-primary text-white rounded-top'>{titulo}</th>
                            </tr>
                        </thead>
                        <thead>
                            <tr className='border border-white text-nowrap'>
                                <th className='text-uppercase text-black-50 fw-medium fs-8 bg-muted'></th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Nome</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Matrícula</th>
                                {nivel === 'Administrador' &&
                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Nivel</th>
                                }
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Email</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Unidade</th>
                                {nivel === 'Administrador' &&
                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Ações</th>
                                }
                            </tr>
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr className='text-nowrap' {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan="7" className='bg-primary text-white rounded-bottom'>
                                    <div className="table-footer d-flex justify-content-between align-items-center">
                                        <span>Exibindo {pageSize < rows.length ? pageSize : rows.length} de {rows.length} registros</span>
                                        <div>
                                            <span>Página {pageIndex + 1} de {pageOptions.length}</span>
                                            <button className="btn btn-outline-primary btn-sm ms-2 text-white" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                <i className="bi bi-caret-left"></i>
                                            </button>
                                            <button className="btn btn-outline-primary btn-sm ms-1 text-white" onClick={() => nextPage()} disabled={!canNextPage}>
                                                <i className="bi bi-caret-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    )

}
export default Table;