import { useMemo, useState, useEffect } from "react";
import TableAulasGrupo from "../../Components/TableAulasGrupo";
import { decodeJWT, formatDateTime } from "../../Components/jwtUtils";
import Api from "../../Auth/Api";
import { Modal } from "react-bootstrap";
import TableInfoAulaGrupo from "../../Components/TableInfoAulaGrupo";

function RelatorioGrupo() {
	const token = JSON.parse(localStorage.getItem("user_token"));
	const tokenDecodifado = decodeJWT(token);
	const nivel = tokenDecodifado.nivel;

	const [dataAulas, setDataAulas] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState(nivel === 'Gestor' ? tokenDecodifado.idUsuario : null);
	const [listFilters, setListFilters] = useState([]);
	const [showModalList, setShowModalList] = useState({ show: false, content: null });
	const [dataInfoAula, setDataInfoAula] = useState([]);

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await Api.get(`api/listar-aulas-grupo/${selectedFilter}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setDataAulas(response.data.data);
			} catch (error) {
				console.log(error.response.data.message);
			}
		}
		fetchData();
	}, [token, selectedFilter]);

	// Fazer lista do filtro
	useEffect(() => {
		async function fetchData() {
			try {
				const response = await Api.get(`api/listar-aulas-grupo/null`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setListFilters(response.data.data);
			} catch (error) {
				console.log(error.response.data.message);
			}
		}
		fetchData();

	}, [token]);

	const columns = useMemo(
		() => [
			{
				Header: "ID",
				accessor: "id"
			},
			{
				Header: () => null,
				id: 'nomeAula',
				accessor: row => row.nomeAula,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex flex-column">
						<div className="fw-semibold">{value}</div>
						<div className="small">{original.nomeModulo}</div>
					</div>
				)
			},
			{
				Header: () => null,
				id: 'nomeGestor',
				accessor: row => row.nomeGestor,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div> <i className="bi bi-person"></i> {value}</div>
				)
			},
			{
				Header: () => null,
				id: 'data',
				accessor: row => row.data,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div> <i className="bi bi-calendar"></i> {formatDateTime(value)}</div>
				)
			},
			{
				Header: () => null,
				id: 'acoes',
				accessor: row => row.id,
				Cell: ({ cell: { value }, row: { original } }) => (
					<div className="d-flex">
						<button onClick={() => setShowModalList({ show: true, content: original })} className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 me-2"><i className="bi bi-eye"></i></button>
					</div>
				)
			},
		],
		[]
	);

	const filterSelect = useMemo(() => {

		const uniqueGestors = new Map();
		listFilters.forEach(aula => {
			if (!uniqueGestors.has(aula.idGestor)) {
				uniqueGestors.set(aula.idGestor, { value: aula.idGestor, label: aula.nomeGestor });
			}
		});
		return Array.from(uniqueGestors.values());

	}, [listFilters]);

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await Api.get(`api/listar-info-aula-grupo/${showModalList.content.id}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setDataInfoAula(response.data);
			} catch (error) {
				console.error(error.response.data.message)
			}
		}

		showModalList.content && fetchData();
	}, [token, showModalList.content]);

	return (
		<>
			<div className="fade-in w-100 mt-3" style={{ marginBottom: '100px' }}>
				<span className="text-primary fs-1">Lista de Aulas em Grupo</span>
				<p className="fs-6">Consulte as aulas realizadas em grupo, filtre e visualize.</p>
				<TableAulasGrupo titulo="Relatórios em grupo" filterSelect={filterSelect} setSelectedFilter={setSelectedFilter} columns={columns} data={dataAulas} />
			</div>

			{showModalList.content &&
				<Modal size="lg" show={showModalList.show} onHide={() => setShowModalList({ show: false, content: null })}>
					<Modal.Header closeButton>
						<span className="fw-semibold fs-4 text-primary">Relátorio de {showModalList.content.nomeGestor}</span>
					</Modal.Header>
					<Modal.Body className="p-0">
						<TableInfoAulaGrupo data={dataInfoAula} />
					</Modal.Body>
					<Modal.Footer>
						<div className="d-flex justify-content-center mb-4 gap-2">
							<button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalList({ show: false, content: null })}>Voltar</button>
						</div>
					</Modal.Footer>
				</Modal>
			}
		</>
	);
}

export default RelatorioGrupo;