import { Link, useLocation } from "react-router-dom";
import { decodeJWT } from "../Components/jwtUtils";
import { useEffect, useState } from "react";
import Api from "../Auth/Api";
import { Modal } from "react-bootstrap";

function Sidebar() {
    const location = useLocation(); // Obter a localização atual
    const token = JSON.parse(localStorage.getItem("user_token"));
    const nivel = decodeJWT(token).nivel;
    const [mensagensNaoLidas, setMensagensNaoLidas] = useState([]);
    const [showModalRelatorios, setShowModalRelatorios] = useState(false);

    // Buscar na API mensagens não lidas
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/mensagens-nao-lidas`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setMensagensNaoLidas(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token]);

    // Definir função para verificar se o link está ativo
    const isActive = (path) => location.pathname.includes(path);

    return (
        <>
            <div className="sidebar-content">
                <div className="d-flex flex-md-column align-items-center bg-light px-2 px-md-4 py-3 py-md-4 sidenav">
                    <Link to={`${nivel === 'Administrador' || nivel === 'Gestor' ? '/dashboard' : '/aluno/dashboard'}`}>
                        <div className={`fs-2 py-0 py-md-1 text-center ${isActive('/dashboard') || isActive('/aluno/dashboard') ? 'menu-item-active' : ''}`} title="Home">
                            <i className="menu-item bi cursor-pointer p-14px rounded bi-house-door d-flex"></i>
                            <span className="d-md-block text-center fs-8 d-none">Home</span>
                        </div>
                    </Link>

                    <Link to="/meus-cursos">
                        <div className={`fs-2 py-0 py-md-1 text-center ${isActive('/meus-cursos') || isActive('/modulos/') || isActive('/aulas/') || isActive('/aula/') ? 'menu-item-active' : ''}`} title="Cursos">
                            <i className="menu-item bi cursor-pointer p-14px rounded bi-book d-flex"></i>
                            <span className="d-md-block text-center fs-8 d-none">Cursos</span>
                        </div>
                    </Link>

                    <Link to="/chat">
                        <div className={`fs-2 py-0 py-md-1 text-center ${isActive('/chat') ? 'menu-item-active' : ''}`} title="Chat">
                            <div className="position-relative">
                                <i className="menu-item bi cursor-pointer p-14px rounded chat bi-chat-left d-flex"></i>
                                <span style={{ display: mensagensNaoLidas.mensagens_nao_lidas > 0 ? "block" : "none", marginLeft: '-10px' }} className="badge bg-danger position-absolute top-0 start-100 translate-middle badge-pill fs-7">{mensagensNaoLidas.mensagens_nao_lidas}</span>
                            </div>
                            <span className="d-md-block text-center fs-8 d-none">Mensagens</span>
                        </div>
                    </Link>

                    <Link to={`${nivel === 'Administrador' || nivel === 'Gestor' ? '/certificados' : '/aluno/certificado'}`}>
                        <div className={`fs-2 py-0 py-md-1 text-center ${isActive('/certificados') || isActive('/aluno/certificado') ? 'menu-item-active' : ''}`} title="Certificados">
                            <i className="menu-item bi cursor-pointer p-14px rounded bi-patch-check d-flex"></i>
                            <span className="d-md-block text-center fs-8 d-none">Certificado</span>
                        </div>
                    </Link>

                    <Link to="/biblioteca">
                        <div className={`fs-2 py-0 py-md-1 text-center ${isActive('/biblioteca') ? 'menu-item-active' : ''}`} title="Biblioteca">
                            <i className="menu-item bi bi-hdd p-14px rounded bi-dash-square d-flex"></i>
                            <span className="d-md-block text-center fs-8 d-none">Biblioteca</span>
                        </div>
                    </Link>

                    {nivel !== 'Aluno' && (
                        <Link onClick={() => setShowModalRelatorios(true)}>
                            <div className={`fs-2 py-0 py-md-1 text-center`} title="Relatórios">
                                <i className="menu-item bi bi-clipboard2-data p-14px rounded bi-dash-square d-flex"></i>
                                <span className="d-md-block text-center fs-8 d-none">Relatórios</span>
                            </div>
                        </Link>
                    )}

                    {nivel === 'Aluno' && (
                        <Link to="/calendario">
                            <div className={`fs-2 py-0 py-md-1 text-center ${isActive('/calendario') ? 'menu-item-active' : ''}`} title="Calendário">
                                <i className="menu-item bi cursor-pointer p-14px rounded bi-calendar d-flex"></i>
                                <span className="d-md-block text-center fs-8 d-none">Calendário</span>
                            </div>
                        </Link>
                    )}

                </div>
            </div>

            <Modal size="lg" show={showModalRelatorios} onHide={() => setShowModalRelatorios(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center w-100">
                        Relatórios
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: "#f9f9f9" }}>
                    <div className="container mt-2">
                        <div className="text-center mb-4">
                            <h5>Selecione um Relatório</h5>
                            <p className="text-muted">Escolha o tipo de relatório que você deseja visualizar.</p>
                        </div>
                        <div className="list-group">
                            <Link
                                to="/relatorios/grupo"
                                onClick={() => setShowModalRelatorios(false)}
                                className="list-group-item list-group-item-action d-flex align-items-center p-3"
                            >
                                <i className="bi bi-people-fill display-4 text-success me-3"></i>
                                <div>
                                    <h5 className="mb-0">Aulas em Grupo</h5>
                                    <small className="text-muted">Visualize o progresso das aulas em grupo.</small>
                                </div>
                            </Link>
                            <Link
                                to="/relatorios/aluno"
                                onClick={() => setShowModalRelatorios(false)}
                                className="list-group-item list-group-item-action d-flex align-items-center p-3"
                            >
                                <i className="bi bi-person-lines-fill display-4 text-primary me-3"></i>
                                <div>
                                    <h5 className="mb-0">Progresso do Aluno</h5>
                                    <small className="text-muted">Acompanhe o progresso individual dos alunos.</small>
                                </div>
                            </Link>
                            <Link
                                to="/relatorios/acompanhamento"
                                onClick={() => setShowModalRelatorios(false)}
                                className="list-group-item list-group-item-action d-flex align-items-center p-3"
                            >
                                <i className="bi bi-credit-card-2-front display-4 text-info me-3"></i>
                                <div>
                                    <h5 className="mb-0">Acompanhamento de Treinamentos</h5>
                                    <small className="text-muted">Monitore o andamento e desempenho dos treinamentos.</small>
                                </div>
                            </Link>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <button className="btn btn-outline-primary" onClick={() => setShowModalRelatorios(false)}>
                        Fechar
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Sidebar;