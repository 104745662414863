import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Api from '../Auth/Api';

const styles = StyleSheet.create({
    page: {
        padding: 30,
        backgroundColor: '#E4E4E4'
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 20
    },
    subTitle: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 20
    },
    question: {
        fontSize: 18,
        margin: 10,
        padding: 10
    },
    question_label: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    answer: {
        fontSize: 16,
        marginLeft: 20,
        marginTop: 5
    },
    correctAnswer: {
        color: 'green'
    },
    incorrectAnswer: {
        color: 'red'
    },
    questionContainer: {
        marginBottom: 50,
        marginTop: 10,
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowRadius: 5,
        shadowOffset: { width: 0, height: 2 },
    },
    questionLabel: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 15,
    },
    alternativeContainer: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginBottom: 10,
        marginHorizontal: 10,
    },
    alternativeLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#555',
        marginRight: 8,
    },
    alternativeText: {
        fontSize: 12,
        color: '#555',
        flexShrink: 1,
    }
});

const GerarPDF = ({ data, titulo, tipo }) => {

    const token = JSON.parse(localStorage.getItem("user_token"));
    const [progressoAvaliacaoExtra, setProgressoAvaliacaoExtra] = useState(null);
    const [progressoAvaliacaoNormal, setProgressoAvaliacaoNormal] = useState(null);

    const [questoes, setQuestoes] = useState({});
    const [respostasUsuario, setRespostasUsuario] = useState({});
    const [alternativasCorretas, setAlternativasCorretas] = useState({});

    useEffect(() => {
        async function fetchDataAvaliacaoExtra(id) {
            try {
                const response = await Api.get(`api/info-progresso-avaliacao-extra/${id}`, { headers: { Authorization: `Bearer ${token}` } });
                setProgressoAvaliacaoExtra(response.data);
            } catch (error) {
                console.error("Erro ao buscar avaliação extra:", error.response.data.message);
            }
        }

        async function fetchDataAvaliacaoNormal(id) {
            try {
                const response = await Api.get(`api/info-avaliacao/${id}`, { headers: { Authorization: `Bearer ${token}` } });
                setProgressoAvaliacaoNormal(response.data);
            } catch (error) {
                console.error("Erro ao buscar avaliação normal:", error.response.data.message);
            }
        }

        if (tipo === 'avaliacao_extra') fetchDataAvaliacaoExtra(data);
        if (tipo === 'avaliacao_normal_visualizacao' || tipo === 'avaliacao_completa_visualizacao') fetchDataAvaliacaoNormal(data);
    }, [token, tipo, data]);

    useEffect(() => {
        if (tipo === "avaliacao_extra" && progressoAvaliacaoExtra) {
            const avaliacaoData = progressoAvaliacaoExtra;
            setQuestoes(JSON.parse(avaliacaoData?.questoes ?? '{}'));
            setRespostasUsuario(JSON.parse(avaliacaoData?.respostas ?? '{}'));
            setAlternativasCorretas(avaliacaoData?.alternativa_correta ?? {});
        }

        if (tipo === "avaliacao") {
            const avaliacaoData = data;
            setQuestoes(JSON.parse(avaliacaoData?.questoes ?? '{}'));
            setRespostasUsuario(JSON.parse(avaliacaoData?.respostas ?? '{}'));
            setAlternativasCorretas(avaliacaoData?.alternativa_correta ?? {});
        }

        if (tipo === 'avaliacao_normal_visualizacao' && progressoAvaliacaoNormal) {
            const avaliacaoData = progressoAvaliacaoNormal;
            setQuestoes(avaliacaoData ?? '{}');
        }

        if (tipo === 'avaliacao_completa_visualizacao' && progressoAvaliacaoNormal) {
            const avaliacaoData = progressoAvaliacaoNormal;
            setQuestoes(avaliacaoData ?? '{}');
        }

    }, [tipo, progressoAvaliacaoExtra, progressoAvaliacaoNormal, data]);

    const displayData = tipo === 'avaliacao_extra' ? progressoAvaliacaoExtra : data;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {(tipo === "avaliacao_extra" || tipo === "avaliacao") &&
                    <Text style={styles.title}>Avaliação de {displayData.nomeUsuario}</Text>
                }
                <Text style={styles.subTitle}>{titulo}</Text>

                {tipo === "avaliacao_normal_visualizacao" &&
                    Object.keys(questoes).map((key, index) => {
                        const questao = questoes[key];
                        const alternativas = JSON.parse(questao.alternativas);
                        return (
                            <View key={index} style={styles.questionContainer}>
                                <Text style={styles.questionLabel}>
                                    {index + 1}. {questao.questao}
                                </Text>
                                {Array.isArray(alternativas) && alternativas.map((alternativa, altIndex) => {
                                    const alternativaLabel = String.fromCharCode(97 + altIndex); // 'a', 'b', 'c', etc.
                                    return (
                                        <View key={altIndex} style={styles.alternativeContainer}>
                                            <Text style={styles.alternativeLabel}>{alternativaLabel})</Text>
                                            <Text style={styles.alternativeText}>{alternativa}</Text>
                                        </View>
                                    );
                                })}
                            </View>
                        );
                    })
                }

                {tipo === "avaliacao_completa_visualizacao" &&
                    Object.keys(questoes).map((key, index) => {
                        const questao = questoes[key];
                        const alternativas = JSON.parse(questao.alternativas);
                        return (
                            <View key={index} style={styles.questionContainer}>
                                <Text style={styles.questionLabel}>
                                    {index + 1}. {questao.questao}
                                </Text>
                                {Array.isArray(alternativas) && alternativas.map((alternativa, altIndex) => {
                                    const alternativaLabel = String.fromCharCode(97 + altIndex); // 'a', 'b', 'c', etc.
                                    // eslint-disable-next-line
                                    const isCorrect = altIndex + 1 == questao.resposta;
                                    return (
                                        <View key={altIndex} style={styles.alternativeContainer}>
                                            <Text style={styles.alternativeLabel}>{alternativaLabel})</Text>
                                            <Text style={[
                                                styles.alternativeText,
                                                isCorrect && { color: 'green' }
                                            ]}>
                                                {alternativa}
                                            </Text>
                                        </View>
                                    );
                                })}
                            </View>
                        );
                    })
                }

                {(tipo === "avaliacao_extra" || tipo === "avaliacao") &&

                    Object.keys(questoes).map((key, index) => {
                        const questao = questoes[key];
                        const respostaUsuario = respostasUsuario[key];
                        const respostaCorreta = alternativasCorretas[key];
                        const isCorrect = respostaCorreta === respostaUsuario;

                        return (
                            <View key={key} style={styles.question}>
                                <Text style={styles.question_label}>{index + 1} - {questao.questao}</Text>
                                <Text
                                    style={[
                                        styles.answer,
                                        isCorrect ? styles.correctAnswer : styles.incorrectAnswer
                                    ]}
                                >
                                    Resposta: {questao.resposta}
                                </Text>
                                {!isCorrect && questao.alternativas && respostaUsuario <= questao.alternativas.length && (
                                    <Text style={styles.incorrectAnswer}>
                                        Resposta do usuário: {questao.alternativas[respostaUsuario - 1]}
                                    </Text>
                                )}
                            </View>
                        );
                    })

                }

                {(tipo === "avaliacao_extra" || tipo === "avaliacao") &&
                    <Text>Total de Acertos: {data.acerto}</Text>
                }
            </Page>
        </Document>
    );
};

export default GerarPDF;