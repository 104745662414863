import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../Auth/Api";
import { Modal, Button, Toast } from "react-bootstrap";
import 'vidstack/styles/defaults.css';
import 'vidstack/styles/community-skin/video.css';
import { MediaCommunitySkin, MediaOutlet, MediaPlayer, MediaPoster } from '@vidstack/react';
import { Tooltip } from "react-tooltip";

function AulaGrupo() {
    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("user_token"));
    const { id } = useParams();
    const [showModalRevise, setShowModalRevise] = useState({ show: false, etapa: 0 });
    const [dataInfoAula, setDataInfoAula] = useState([]);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [showFinalizeModal, setShowFinalizeModal] = useState(false);
    const [presenceFile, setPresenceFile] = useState(null);
    const [presenceFileUploaded, setPresenceFileUploaded] = useState(false);
    const arquivoPrincipal = dataInfoAula[0] && JSON.parse(dataInfoAula[0].arquivos)[0];
    const validExtensions = ["mp4", "avi", "mkv", "mov"];
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [loading, setLoading] = useState(false);

    let urlPrincipal = '';
    const html_externo = dataInfoAula[0] && dataInfoAula[0].html_externo;
    if (html_externo) {
        urlPrincipal = html_externo;
    } else {
        urlPrincipal = `${process.env.REACT_APP_FILES_URL}/${arquivoPrincipal}&token=${token}`;
    }

    let listaArquivos = [];

    if (dataInfoAula[0] && dataInfoAula[0].arquivos) {
        const parsedData = JSON.parse(dataInfoAula[0].arquivos);
        if (Array.isArray(parsedData)) {
            listaArquivos = parsedData;
        }
    }

    listaArquivos = listaArquivos.filter(item => item);

    listaArquivos.splice(0, 1);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/info-aula/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataInfoAula(response.data);
            } catch (error) {
                console.error(error.response.data.message)
                navigate("/");
            }
        }

        fetchData();
    }, [token, id, navigate]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-usuarios-do-curso/${dataInfoAula[0].idCurso}/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setAvailableUsers(response.data);

            } catch (error) {
                console.error(error)
            }
        }

        dataInfoAula[0] && fetchData();
    }, [token, id, dataInfoAula]);

    const [availableUsers, setAvailableUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchTermAvailable, setSearchTermAvailable] = useState('');
    const [searchTermSelected, setSearchTermSelected] = useState('');

    const handleSelectUser = (user) => {
        setAvailableUsers(availableUsers.filter(u => u.id !== user.id));
        setSelectedUsers([...selectedUsers, user]);
    };

    const handleDeselectUser = (user) => {
        setSelectedUsers(selectedUsers.filter(u => u.id !== user.id));
        setAvailableUsers([...availableUsers, user]);
    };

    const handleFinalize = async () => {

        setLoading(true);

        const payload = new FormData();
        payload.append('usuarios', JSON.stringify(selectedUsers.map(user => user.id)));
        payload.append('arquivo', presenceFile);
        try {
            await Api.post(`/api/finalizar-aula/${id}`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setLoading(false);
            setShowModalRevise({ show: false, etapa: 3 });
        } catch (error) {
            setToast({ header: 'Erro!', body: error.response.data.message });
            setShowToast(true);
            console.error('Erro ao finalizar aula:', error.response.data.message);
        }

        setShowFinalizeModal(false);
    };

    const handlePresenceFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPresenceFile(file);
            setPresenceFileUploaded(true);
        }
    };

    const filteredAvailableUsers = availableUsers.filter(user =>
        user.nome_completo.toLowerCase().includes(searchTermAvailable.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTermAvailable.toLowerCase()) ||
        user.codigo.toLowerCase().includes(searchTermAvailable.toLowerCase())
    );

    const filteredSelectedUsers = selectedUsers.filter(user =>
        user.nome_completo.toLowerCase().includes(searchTermSelected.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTermSelected.toLowerCase()) ||
        user.codigo.toLowerCase().includes(searchTermSelected.toLowerCase())
    );

    // Scroll to video player when starting class
    useEffect(() => {
        if (showModalRevise.etapa === 2) {
            const element = document.getElementById("aula");
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
                setTimeout(() => {
                    window.scrollBy({ top: -80, behavior: "smooth" });
                }, 500);
            }
        }
    }, [showModalRevise.etapa]);

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };

    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>
            {dataInfoAula[0] &&
                <div className="fade-in w-100">

                    {showModalRevise.etapa === 2 &&
                        <div className="position-fixed bottom-0 end-0 m-5">
                            <button className="btn btn-warning me-2" id="telacheia" style={{ zIndex: 9 }} onClick={toggleFullscreen}>
                                {isFullscreen ? <i className="bi bi-arrows-angle-contract"></i> : <i className="bi bi-arrows-angle-expand"></i>}
                                <Tooltip anchorSelect="#telacheia" place="top">
                                    {isFullscreen ? 'Diminuir' : 'Aumentar'}
                                </Tooltip>
                            </button>
                            <button className="btn btn-success" id="finalizar_aula" style={{ zIndex: 9 }} onClick={() => setShowFinalizeModal(true)}>
                                <i className="bi bi-check-lg"></i>
                                <Tooltip anchorSelect="#finalizar_aula" place="top">
                                    Finalizar aula
                                </Tooltip>
                            </button>
                        </div>
                    }

                    <Link onClick={() => navigate(-1)} className="btn text-primary link border-0 fs-6 my-3 my-md-0"><i className="bi bi-chevron-left"></i> Voltar </Link>

                    {showModalRevise.etapa !== 3 &&
                        <>
                            <div className="card card-flag rounded-5 w-100 d-none d-md-block overflow-hidden mb-4 border-none card-flag-grupo">
                                <div className="position-absolute w-100 h-100" style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}></div>
                                <img src={`${process.env.REACT_APP_IMG_URL}/${dataInfoAula[0].thumbnail}`} className="card-img w-100 card-img-banner" alt="NR 01 - ORDEM DE SERVIÇO" />
                                <div className="card-img-overlay d-flex flex-column justify-content-center">
                                    <div className="text-white fs-1 text-center">
                                        {dataInfoAula[0].titulo} <p className="fs-6">Realização em Grupo
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="body">
                                <section className="clearfix w-100 mt-4 d-block" id="aula" style={{ marginBottom: window.innerWidth < 769 ? '100px' : '20px' }}>

                                    {showModalRevise.etapa !== 2 &&
                                        <div className="container mt-4 mb-5">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <h3>Alunos disponíveis</h3>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-3"
                                                        placeholder="Buscar alunos"
                                                        value={searchTermAvailable}
                                                        onChange={(e) => setSearchTermAvailable(e.target.value)}
                                                    />
                                                    <ul className="list-group overflow-auto" style={{ height: 300 }}>
                                                        {filteredAvailableUsers.map(user => (
                                                            <li key={user.id} className="list-group-item d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <div>
                                                                        {user.nome_completo}
                                                                    </div>
                                                                    <div>
                                                                        {user.codigo && <span className="badge bg-secondary rounded-pill">{user.codigo}</span>}
                                                                    </div>
                                                                </div>
                                                                <button className="btn btn-primary btn-sm" onClick={() => handleSelectUser(user)}>Selecionar</button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                <div className="col-md-5 offset-md-2 mt-md-0 mt-4">
                                                    <h3>Alunos selecionados</h3>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-3"
                                                        placeholder="Buscar alunos"
                                                        value={searchTermSelected}
                                                        onChange={(e) => setSearchTermSelected(e.target.value)}
                                                    />
                                                    <ul className="list-group overflow-auto" style={{ height: 300 }}>
                                                        {filteredSelectedUsers.map(user => (
                                                            <li key={user.id} className="list-group-item d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <div>
                                                                        {user.nome_completo}
                                                                    </div>
                                                                    <div>
                                                                        {user.codigo && <span className="badge bg-secondary rounded-pill">{user.codigo}</span>}
                                                                    </div>
                                                                </div>
                                                                <button className="btn btn-danger btn-sm" onClick={() => handleDeselectUser(user)}>Desmarcar</button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>

                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <button
                                                        className="btn btn-primary"
                                                        disabled={filteredSelectedUsers.length > 0 ? "" : "disabled"}
                                                        onClick={() => setShowModalRevise({ show: true, etapa: 1 })}
                                                    >
                                                        Iniciar aula
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {arquivoPrincipal !== undefined && showModalRevise.etapa === 2 &&
                                        <div className="body">
                                            <section className="w-100 d-flex flex-wrap">
                                                <div className={`video rounded-3 overflow-hidden bg-dark h-100 ${isFullscreen ? 'col-12' : 'col-md-8'}`}>
                                                    {validExtensions.includes(dataInfoAula[0].extensao) ?
                                                        <div className="video-player-container" style={{ zoom: 0.8 }}>
                                                            <MediaPlayer
                                                                title={dataInfoAula[0].titulo}
                                                                src={urlPrincipal}
                                                                aspectRatio={16 / 9}
                                                                crossorigin="allow"
                                                            >
                                                                <MediaOutlet>
                                                                    <MediaPoster alt={dataInfoAula[0].titulo} />
                                                                </MediaOutlet>
                                                                <MediaCommunitySkin />
                                                            </MediaPlayer>
                                                        </div>
                                                        :
                                                        <iframe className="doc_aula" src={urlPrincipal} width="100%" title={dataInfoAula[0].titulo} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                    }
                                                </div>

                                                {!isFullscreen && (
                                                    <div className="video rounded-3 overflow-hidden col-md-4 mb-3 p-4">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <span className="fs-3 fw-500 text-dark">Alunos selecionados</span>
                                                        </div>
                                                        <div className="d-flex flex-wrap overflow-auto" style={{ height: 500 }}>
                                                            <ul className="list-group w-100">
                                                                {selectedUsers.map((user, index) => (
                                                                    <li key={index} className="list-group-item">
                                                                        {user.nome_completo}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </section>
                                        </div>
                                    }

                                    <div className="card mt-3 py-3 px-4 bg-primary text-white back-image border-none">
                                        <div className="d-flex align-items-center row g-0">
                                            {dataInfoAula[0].arquivos && listaArquivos.map((value, index) => (
                                                <div key={index} className="col-md-3 overflow-hidden bg-white text-center rounded m-2">
                                                    <span className="d-flex bg-grey w-100 space-grey"></span>
                                                    <Link target="_blank" to={`${process.env.REACT_APP_FILES_URL}/${value}&token={token}`}>
                                                        <button type="button" className="btn fs-6 text-primary link py-3">
                                                            Baixar conteúdo <i className="bi bi-download ms-2"></i>
                                                        </button>
                                                    </Link>
                                                </div>
                                            ))}

                                            <div className="col-md-3">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                                        <span className="fs-3 fw-500">{dataInfoAula[0].titulo}</span>
                                                    </div>
                                                    <span className="fw-light text-break">
                                                        {dataInfoAula[0].descricao}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </>
                    }

                    {showModalRevise.etapa === 3 &&
                        <>
                            <div className="row justify-content-center align-items-center mt-4">
                                <div className="col-md-8 col-lg-6">
                                    <div className="fade-in w-100 mt-3">
                                        <div className="bg-white rounded p-5 mt-3 shadow text-center">
                                            <div className="row">
                                                <div className="col-12">
                                                    <i className="bi bi-check-circle text-success mb-3" style={{ fontSize: 60 }}></i>
                                                    <h1 className="text-success">Aula Concluída!</h1>
                                                    <p className="lead mt-3">Parabéns! A aula foi concluida com sucesso.</p>
                                                    <Link to={`/aulas/${dataInfoAula && dataInfoAula[0].idModulo}`} className="btn btn-primary mt-4">
                                                        Voltar para Aulas
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </div >
            }

            <Modal size="lg" show={showModalRevise.show} onHide={() => setShowModalRevise({ show: false, etapa: 0 })}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Revise e confirme</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex">
                                        <div className="d-flex flex-column w-100">
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>Tem certeza que deseja <b>inicia a aula</b>?</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mb-4 gap-2">
                                        <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalRevise({ show: false, etapa: 0 })}>Revisar</button>
                                        <button type="submit" className="btn btn-primary text-white px-5 py-2" onClick={() => setShowModalRevise({ show: false, etapa: 2 })}>Iniciar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={showFinalizeModal} onHide={() => setShowFinalizeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Finalizar Aula
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="overflow-auto" style={{ maxHeight: 450 }}>
                        <div className="fw-normal fs-6 text-dark">
                            Você tem certeza que deseja finalizar a aula ?
                        </div>
                        <div className="fw-semibold fs-6 text-dark">
                            Antes de finalizar a aula você precisa enviar uma foto ou arquivo com a lista de presença.
                        </div>
                        <div className="fw-normal text-danger w-100 mt-2">Essa ação não poderá ser desfeita.</div>
                        <div className="d-flex justify-content-start mt-3">
                            <input
                                type="file"
                                id="presence-file-input"
                                accept=".pdf, image/*"
                                onChange={handlePresenceFileChange}
                                style={{ display: 'none' }}
                            />
                            <label htmlFor="presence-file-input" className="btn btn-dark">
                                Enviar Lista de Presença
                            </label>
                            {presenceFileUploaded && <span className="ms-2 mt-1"><span className="fs-7">Arquivo enviado</span> <i className="bi bi-check2-circle text-success"></i></span>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-primary text-primary" onClick={() => setShowFinalizeModal(false)}>
                        Cancelar
                    </button>
                    <Button variant="primary" onClick={handleFinalize} disabled={!presenceFile || loading}>
                        {loading ? <div className="spinner-border fs-7" style={{ width: 20, height: 20, top: 2, position: 'relative' }} role="status"></div> : 'Finalizar'}
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default AulaGrupo;
